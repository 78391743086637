import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';

import { Loader } from 'ui';

import { CAMPAIGN_TYPES } from 'common/constants';
import { getNetworkError, parseDonationAmount } from 'common/utils';
import { type Report } from 'common/types';
import { getPercentage } from 'core/utils';
import CustomerNames from 'components/customers/CustomerNames';
import { getReports } from 'core/api';
import { useToast } from 'hooks';

const RecentCampaignsCard = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [reports, setReports] = useState<Report[]>([]);
  useToast('recent-campaigns-error', 'error', error, setError);

  const getRecentCampaigns = useCallback(async () => {
    setLoading(true);
    try {
      const reports = await getReports(CAMPAIGN_TYPES.single);
      setReports(reports);
    } catch (err) {
      setError(getNetworkError(err));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    void getRecentCampaigns();
  }, [getRecentCampaigns]);

  return (
    <div className="relative space-y-4 rounded-lg bg-white-100 px-6 py-2">
      <h3 className="text-base font-semibold">Recent Campaigns</h3>
      <div className="h-[500px] overflow-y-auto">
        <table className="donations-table w-full table-fixed text-left text-base text-gray-950">
          <thead className="sticky top-0 bg-white-100 text-sm uppercase text-gray-400">
            <tr>
              <th className="w-[190px] py-2 pr-4">Name</th>
              <th className="w-[160px] px-4 py-2">Customer</th>
              <th className="w-[160px] px-4 py-2">Donations</th>
              <th className="w-[100px] px-4 py-2">Audience</th>
              <th className="w-[80px] px-4 py-2">Unsub</th>
              <th className="w-[80px] px-4 py-2">Spam</th>
              <th className="w-[80px] px-4 py-2">Bounce</th>
              <th className="w-[80px] px-4 py-2">Deliver</th>
              <th className="w-[80px] px-4 py-2">Click</th>
              <th className="w-[80px] px-4 py-2">Open</th>
              <th className="w-[80px] px-4 py-2">Deferred</th>
              <th className="w-[80px] px-4 py-2">Dropped</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report.campaignId} className="h-[48px] border-b border-gray-50">
                <td className="py-1 pr-4">
                  <div className="overflow-hidden text-ellipsis text-nowrap" title={report.name}>
                    {report.name}
                  </div>
                  <div className="text-sm text-gray-600">
                    Started {format(new Date(Number(report.sendAt) * 1000), "MMM d 'at' h:mma")}
                  </div>
                </td>
                <td className="px-4 py-1">
                  <CustomerNames tenantIds={[report.tenantId]} excludedTenantIds={[]} />
                </td>
                <td className="px-4 py-1">
                  <div className="overflow-hidden text-ellipsis text-nowrap" title={report.name}>
                    {parseDonationAmount(Number(report.amountRaised ?? 0) / 100)}
                  </div>
                  <div className="text-sm text-gray-600">{report.donations ?? 0} donations</div>
                </td>
                <td className="px-4 py-1">{report.totalContacts}</td>
                <td className="px-4 py-1">{getPercentage(report.unsubscribe || 0, report.totalContacts, 2)}</td>
                <td className="px-4 py-1">{getPercentage(report.spamreport || 0, report.totalContacts, 2)}</td>
                <td className="px-4 py-1">{getPercentage(report.bounce || 0, report.totalContacts, 2)}</td>
                <td className="px-4 py-1">{getPercentage(report.delivered || 0, report.totalContacts)}</td>
                <td className="px-4 py-1">{getPercentage(report.uniqueClicks || 0, report.totalContacts)}</td>
                <td className="px-4 py-1">{getPercentage(report.uniqueOpens || 0, report.totalContacts)}</td>
                <td className="px-4 py-1">{getPercentage(report.deferred || 0, report.totalContacts)}</td>
                <td className="px-4 py-1">{getPercentage(report.dropped || 0, report.totalContacts)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {loading && <Loader />}
    </div>
  );
};

export default RecentCampaignsCard;
