import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import mergeWith from 'lodash.mergewith';
import { format } from 'date-fns';
import { getNetworkError } from 'common/utils';

import {
  getTemplate as getTemplateApi,
  createSuggestedDraft as createSuggestedDraftApi,
  updateSuggestedDraft as updateSuggestedDraftApi,
  sendSuggestedDraft as sendSuggestedDraftApi,
  createSuggestedDraftTemplate as createSuggestedDraftTemplateApi,
  uploadTemplate as uploadTemplateApi,
  updateSuggestedDraftTemplate as updateSuggestedDraftTemplateApi,
} from 'core/api';
import { validateSuggestedDraft } from 'pages/create-suggested-draft/utils';
import { useCustomersStore } from 'stores/customers/store';
import { type SuggestedDraftData } from 'core/types';
import { getCustomerNames } from 'core/utils';
import { type DraftDetails, type DraftTemplate, type SuggestedDraftsActions, type SuggestedDraftsState } from './types';

// Also used for local storage key if using persist middleware
const STORE_NAME = 'suggestedDraftsStore';

const customizedMerge = (objValue: any, srcValue: any): any => {
  if (srcValue === '' || Array.isArray(objValue)) {
    return srcValue;
  }
};

const initialDraftDetails: DraftDetails = {
  draftId: '',
  tempDraftId: '',
  tenantIds: [],
  excludedTenantIds: [],
  expirationDays: 14,
  name: '',
  sendTo: {
    all: false,
    segmentIds: [],
    tagIds: [],
  },
  exclude: {
    segmentIds: [],
    tagIds: [],
  },
  emailConfig: {
    senderName: '',
    senderEmail: '',
    subject: '',
    previewText: '',
  },
  sendTime: '',
  template: {
    templateId: '',
    version: '',
  },
  message:
    'The DonorSpring team has crafted a new draft campaign for you based on our suggested best practices. After accepting, you can still make your own changes and customizations before launching the campaign to the selected audience.',
  scheduleSend: false,
  customerSendTime: '',
};

const initialDraftTemplate: DraftTemplate = {
  templateName: '',
  templateId: '',
  templateVersion: '',
  createTimestamp: '',
  uploadStatus: '',
  notes: '',
  design: '',
  html: '',
};

const initialState: SuggestedDraftsState = {
  draftDetails: { ...initialDraftDetails },
  draftTemplate: { ...initialDraftTemplate },
  loading: false,
  error: '',
  success: '',
};

export const useSuggestedDraftsStore = create<SuggestedDraftsState & SuggestedDraftsActions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        /**
         * Sync Actions
         */
        updateDraftDetails: (update) => {
          set((state) => ({ draftDetails: mergeWith({}, state.draftDetails, update, customizedMerge) }));
        },
        updateSentTo: (update) => {
          set((state) => ({
            draftDetails: { ...state.draftDetails, sendTo: { ...state.draftDetails.sendTo, ...update } },
          }));
        },
        updateExclude: (update) => {
          set((state) => ({
            draftDetails: { ...state.draftDetails, exclude: { ...state.draftDetails.exclude, ...update } },
          }));
        },
        updateEmailConfig: (update) => {
          set((state) => ({
            draftDetails: { ...state.draftDetails, emailConfig: { ...state.draftDetails.emailConfig, ...update } },
          }));
        },
        setDraftDetails: (details) => {
          set((state) => ({ draftDetails: mergeWith({}, initialDraftDetails, details, customizedMerge) }));
        },
        setError: (err) => {
          set({ error: err });
        },
        setSuccess: (message) => {
          set({ success: message });
        },
        resetState: () => {
          set(() => mergeWith({}, initialState, customizedMerge));
        },
        validateDraftDetails: () => {
          const draftDetails = get().draftDetails;
          const errorMessage = validateSuggestedDraft(draftDetails);
          if (errorMessage) {
            set({ error: errorMessage });
          }
          return errorMessage;
        },
        resetSingleCustomerDetails: () => {
          set((state) => ({
            draftDetails: {
              ...state.draftDetails,
              sendTo: { ...initialDraftDetails.sendTo },
              exclude: { ...initialDraftDetails.exclude },
              emailConfig: { ...state.draftDetails.emailConfig, senderName: '', senderEmail: '' },
            },
          }));
        },
        getSendConfirmationMessage: () => {
          const { tenantIds, excludedTenantIds, name, scheduleSend, customerSendTime } = get().draftDetails;
          const customers = useCustomersStore.getState().customers;
          const customerNames = getCustomerNames(tenantIds, customers);
          let message = `Are you sure you want to send ${name} to: ${customerNames}`;

          if (excludedTenantIds?.length > 0) {
            const excludedCustomerNames = getCustomerNames(excludedTenantIds, customers);
            message += `, excluding: ${excludedCustomerNames}`;
          }

          if (scheduleSend) {
            message += ` at ${format(new Date(customerSendTime), 'dd MMM yyyy, h:mm a')}?`;
          } else {
            message += ' immediately?';
          }
          return message;
        },
        /**
         * Async Actions
         */
        createSuggestedDraft: async () => {
          set({ error: '', loading: true });
          try {
            const { tempDraftId, scheduleSend, customerSendTime, ...draft } = get().draftDetails;
            const draftData: SuggestedDraftData = { ...draft, draftId: tempDraftId, customerSendTime: '' };
            if (scheduleSend) {
              draftData.customerSendTime = customerSendTime;
            }
            await createSuggestedDraftApi(draftData);
            set((state) => ({ draftDetails: { ...state.draftDetails, tempDraftId: '', draftId: draftData.draftId } }));
          } catch (err) {
            set({ error: getNetworkError(err) });
          }
          set({ loading: false });
        },
        updateSuggestedDraft: async ({ onSuccess, onError } = {}) => {
          set({ error: '', loading: true });
          try {
            const { tempDraftId, scheduleSend, customerSendTime, ...draft } = get().draftDetails;
            const draftData: SuggestedDraftData = { ...draft, customerSendTime: '' };
            if (scheduleSend) {
              draftData.customerSendTime = customerSendTime;
            }
            await updateSuggestedDraftApi(draftData);
            if (onSuccess) onSuccess();
          } catch (err) {
            set({ error: getNetworkError(err) });
            if (onError) onError();
          }
          set({ loading: false });
        },
        createSuggestedDraftTemplate: async ({ design, html }, { onSuccess, onError } = {}) => {
          set({ error: '', loading: true });
          try {
            const { tenantIds } = get().draftDetails;
            const response = await createSuggestedDraftTemplateApi({ design, tenantIds });
            const template = response.data;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await uploadTemplateApi(template.uploadUrl!, html);
            set((state) => ({
              draftDetails: {
                ...state.draftDetails,
                template: { templateId: template.templateId, version: template.templateVersion },
              },
              draftTemplate: {
                templateName: template.templateName,
                templateId: template.templateId,
                templateVersion: template.templateVersion,
                createTimestamp: template.createTimestamp,
                uploadStatus: template.uploadStatus,
                notes: template.notes ?? '',
                design,
                html,
              },
            }));
            await get().updateSuggestedDraft({ onSuccess });
          } catch (err) {
            set({ error: getNetworkError(err) });
            if (onError) onError();
          }
          set({ loading: false });
        },
        updateSuggestedDraftTemplate: async ({ templateId, version, design, html }, { onSuccess, onError } = {}) => {
          set({ error: '', loading: true });
          try {
            const { tenantIds } = get().draftDetails;
            const response = await updateSuggestedDraftTemplateApi({ templateId, version, design, tenantIds });
            const template = response.data;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await uploadTemplateApi(template.uploadUrl!, html);
            const currentDraftDetailsTemplate = get().draftDetails.template;
            set((state) => ({
              draftDetails: {
                ...state.draftDetails,
                template: { templateId: template.templateId, version: template.templateVersion },
              },
              draftTemplate: {
                templateName: template.templateName,
                templateId: template.templateId,
                templateVersion: template.templateVersion,
                createTimestamp: template.createTimestamp,
                uploadStatus: template.uploadStatus,
                notes: template.notes ?? '',
                design,
                html,
              },
            }));
            if (currentDraftDetailsTemplate.version !== template.templateVersion) {
              await get().updateSuggestedDraft({ onSuccess });
            } else if (onSuccess) {
              onSuccess();
            }
          } catch (err) {
            set({ error: getNetworkError(err) });
            if (onError) onError();
          }
          set({ loading: false });
        },
        getSuggestedDraftTemplate: async ({ templateId, version }, { onSuccess, onError } = {}) => {
          set({ error: '', loading: true });
          try {
            const template = await getTemplateApi(templateId, version);
            set({
              draftTemplate: {
                templateName: template.templateName,
                templateId: template.templateId,
                templateVersion: template.templateVersion,
                createTimestamp: template.createTimestamp,
                uploadStatus: template.uploadStatus,
                notes: template.notes ?? '',
                design: template.design,
                html: template.html,
              },
            });
            if (onSuccess) onSuccess();
          } catch (err) {
            set({ error: getNetworkError(err) });
            if (onError) onError();
          }
          set({ loading: false });
        },
        sendSuggestedDraft: async ({ onSuccess, onError } = {}) => {
          set({ error: '', loading: true });
          try {
            const { tempDraftId, scheduleSend, customerSendTime, ...draft } = get().draftDetails;
            const draftData: SuggestedDraftData = { ...draft, customerSendTime: '' };
            if (scheduleSend) {
              draftData.customerSendTime = customerSendTime;
            }
            await sendSuggestedDraftApi(draftData);
            if (onSuccess) onSuccess();
          } catch (err) {
            set({ error: getNetworkError(err) });
            if (onError) onError();
          }
          set({ loading: false });
        },
      }),
      { name: STORE_NAME },
    ),
  ),
);
