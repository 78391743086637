import { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useInterval } from 'usehooks-ts';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getNetworkError } from 'common/utils';
import { Button, Chip } from 'ui';

import { SETTINGS } from 'core/constants';
import { CustomerStatus, type ICustomer } from 'core/types';
import { Loader } from 'components/loader';
import { ErrorToast } from 'components/toasts';
import { CustomersTable, CustomersTableFilters, OnboardCustomerModal } from '../customers';
import { type TableFilters } from '../../types';

const API_URL = `${SETTINGS.apiUrl}/customer-management`;

const POLLING_INTERVAL_IN_MS = 15000;

const AllCustomersCard = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [customerErrors, setCustomerErrors] = useState<ICustomer[]>([]);
  const [openOnboard, setOpenOnboard] = useState(false);
  const [tableFilters, setTableFilters] = useState<TableFilters>({ active: true });
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const getCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URL);
      setCustomers(response.data as ICustomer[]);
      setCustomerErrors(
        response.data.filter((customer: ICustomer) => customer.status === CustomerStatus.ERROR) as ICustomer[],
      );
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'getCustomersError',
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    void getCustomers();
  }, [getCustomers]);

  const isInProgress = useMemo(() => {
    return customers.some(({ status }) => status === CustomerStatus.PROCESSING);
  }, [customers]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useInterval(getCustomers, isInProgress ? POLLING_INTERVAL_IN_MS : null);

  const displayError = useCallback(
    (customerError: ICustomer) => {
      toast.custom(
        (t) => (
          <ErrorToast
            visible={t.visible}
            message={
              <>
                <span>Onboarding error for customer </span>
                <span
                  className="cursor-pointer underline"
                  onClick={() => {
                    toast.remove(t.id);
                    navigate(`/customer/${customerError.tenantId}`, { state: { customer: customerError } });
                  }}
                >
                  {customerError.name ?? customerError.email ?? customerError.tenantId}
                </span>
              </>
            }
            onClose={() => {
              toast.remove(t.id);
              setTimeout(() => {
                setCustomerErrors((prevState) => prevState.filter((item) => item.tenantId !== customerError.tenantId));
              }, 200);
            }}
          />
        ),
        { id: customerError.tenantId, duration: Infinity },
      );
    },
    [navigate],
  );

  useEffect(() => {
    if (customerErrors.length === 0 || tableFilters.active) return;
    displayError(customerErrors[0]);
  }, [customerErrors, displayError, tableFilters.active]);

  const customersData = useMemo(() => {
    let filteredCustomers = [...customers];
    if (tableFilters.active) {
      filteredCustomers = filteredCustomers.filter(({ status, active }) =>
        typeof active !== 'undefined' ? !!active : status === CustomerStatus.DONE,
      );
    }
    if (tableFilters.active === false) {
      filteredCustomers = filteredCustomers.filter(({ status, active }) =>
        typeof active !== 'undefined' ? !active : status !== CustomerStatus.DONE,
      );
    }
    if (tableFilters.sandbox) {
      filteredCustomers = filteredCustomers.filter(({ sandbox }) => !!sandbox);
    }
    return filteredCustomers;
  }, [customers, tableFilters]);

  const activeFilters = Object.values(tableFilters).filter((filter) => typeof filter !== 'undefined').length > 0;

  return (
    <div className="space-y-4 rounded-lg bg-white-100 px-6 py-2">
      <h3 className="text-base font-semibold">All Customers</h3>
      <div className="flex flex-col space-y-6">
        <div className="flex items-center justify-between">
          <div className="relative space-y-2 self-center">
            {activeFilters && (
              <div className="flex items-center space-x-3">
                {tableFilters.active && (
                  <Chip
                    label="Active"
                    onDelete={() => {
                      setTableFilters((prev) => ({ ...prev, active: undefined }));
                    }}
                  />
                )}
                {tableFilters.active === false && (
                  <Chip
                    label="Inactive"
                    onDelete={() => {
                      setTableFilters((prev) => ({ ...prev, active: undefined }));
                    }}
                  />
                )}
                {tableFilters.sandbox && (
                  <Chip
                    label="Sandbox"
                    onDelete={() => {
                      setTableFilters((prev) => ({ ...prev, sandbox: undefined }));
                    }}
                  />
                )}
                <button
                  className="text-sm text-gray-600"
                  onClick={() => {
                    setTableFilters({});
                  }}
                >
                  Reset all
                </button>
              </div>
            )}
          </div>
          <div className="space-x-3">
            <CustomersTableFilters filters={tableFilters} setFilters={setTableFilters} />

            <Button
              title="Onboard Free Customer"
              color="primary"
              onClick={() => {
                toast.remove();
                setOpenOnboard(true);
              }}
            />
          </div>
        </div>

        <div className="relative">
          <CustomersTable data={customersData} />

          {loading && <Loader />}
        </div>

        <OnboardCustomerModal
          open={openOnboard}
          onClose={() => {
            setOpenOnboard(false);
          }}
          setCustomers={setCustomers}
        />
      </div>
    </div>
  );
};

export default AllCustomersCard;
