import axios from 'axios';

import { SETTINGS } from 'core/constants';
import { type CampaignType, type Report } from 'common/types';

const API_URL = `${SETTINGS.apiUrl}/customer-management`;

export const getReports = async (type: CampaignType): Promise<Report[]> => {
  const response = await axios.get(`${API_URL}/reports`, { params: { type } });
  return response.data;
};
