import { useMemo } from 'react';
import { useCustomersStore } from 'stores/customers/store';
import { getCustomerNames } from 'core/utils';

type CustomerNamesProps = {
  tenantIds: string[];
  excludedTenantIds: string[];
};

const CustomerNames = (props: CustomerNamesProps): JSX.Element => {
  const { tenantIds, excludedTenantIds } = props;
  const customers = useCustomersStore((state) => state.customers);

  const customerNames = useMemo(() => {
    const customerNames = getCustomerNames(tenantIds, customers);
    if (excludedTenantIds.length) {
      return `${customerNames} excl. ${getCustomerNames(excludedTenantIds, customers)}`;
    }
    return getCustomerNames(tenantIds, customers);
  }, [customers, tenantIds, excludedTenantIds]);

  return (
    <p className="max-w-[220px] overflow-hidden text-ellipsis text-nowrap" title={customerNames}>
      {customerNames}
    </p>
  );
};

export default CustomerNames;
