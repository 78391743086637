import { AllCustomersCard, RecentCampaignsCard } from './components/cards';

const Home = (): JSX.Element => {
  return (
    <div className="space-y-6">
      <AllCustomersCard />
      <RecentCampaignsCard />
    </div>
  );
};

export default Home;
