import { type Dispatch, type FC, Fragment, type MouseEventHandler, type SetStateAction } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Button } from 'ui';

import { Checkbox, RadioInput } from 'components/inputs';
import { type TableFilters } from 'pages/home/types';

type FiltersProps = {
  filters: TableFilters;
  setFilters: Dispatch<SetStateAction<TableFilters>>;
};

const CustomersTableFilters: FC<FiltersProps> = ({ filters, setFilters }) => {
  const handleClickPreventDefault: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!(event.target as HTMLElement).id) {
      // Workaround to only close menu if we click on an input or a button.
      // Issue here is that we cannot avoid closing the menu when selecting or clearing filters.
      // @debt - let's consider using another ui lib.
      event.preventDefault();
    }
  };

  return (
    <>
      <Menu as="div" className="relative inline-block">
        <Menu.Button as={Fragment}>
          <Button title="Filters" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute	
            right-0 
            z-50 
            mt-2 
            w-56 
            origin-top-right 
            divide-y 
            divide-gray-100 
            rounded-lg 
            bg-white-100 
            shadow-lg 
            focus:outline-none"
          >
            <Menu.Item as="div" onClick={handleClickPreventDefault}>
              <div className="flex items-center justify-between px-4 py-3">
                <p className="text-base font-semibold text-gray-950">Filters</p>
                <button
                  id="reset-all"
                  className="text-sm text-gray-600"
                  onClick={() => {
                    setFilters({});
                  }}
                >
                  Reset all
                </button>
              </div>
            </Menu.Item>
            <Menu.Item as="div" onClick={handleClickPreventDefault}>
              <div className="px-4 py-3">
                <RadioInput
                  id="active-accounts"
                  label="Active"
                  value=""
                  checked={typeof filters.active !== 'undefined' && !!filters.active}
                  onChange={(event) => {
                    setFilters((prev) => ({ ...prev, active: event.target.checked ? true : undefined }));
                  }}
                />

                <RadioInput
                  id="inactive-accounts"
                  label="Inactive"
                  value=""
                  checked={typeof filters.active !== 'undefined' && !filters.active}
                  onChange={(event) => {
                    setFilters((prev) => ({ ...prev, active: event.target.checked ? false : undefined }));
                  }}
                />

                <Checkbox
                  id="sandbox-accounts"
                  label="Sandbox"
                  containerClassName="!mb-0"
                  labelClassName="!mb-0"
                  value=""
                  checked={typeof filters.sandbox !== 'undefined' && !!filters.sandbox}
                  onChange={(event) => {
                    setFilters((prev) => ({ ...prev, sandbox: event.target.checked ? true : undefined }));
                  }}
                />
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default CustomersTableFilters;
