import { type DraftDetails } from 'stores/suggested-drafts';

import { formValidation as customerSendTimeValidation } from './components/forms/CustomerSendTimeForm';
import { formValidation as nameValidation } from './components/forms/CampaignNameForm';
import { formValidation as expirationDaysValidation } from './components/forms/ExpirationForm';
import { formValidation as subjectValidation } from './components/forms/SubjectForm';
import { formValidation as sendTimeValidation } from './components/forms/TimeForm';
import { formValidation as messageValidation } from './components/forms/MessageForm';

export const validateSuggestedDraft = (draft: DraftDetails): string | null => {
  const { tenantIds, expirationDays, name, emailConfig, sendTime, template, message, scheduleSend, customerSendTime } =
    draft;
  try {
    if (tenantIds.length === 0) {
      return 'Please select at least one customer.';
    }
    if (scheduleSend) {
      customerSendTimeValidation.validateSync({ customerSendTime });
    }
    expirationDaysValidation.validateSync({ expirationDays });
    nameValidation.validateSync({ name });
    subjectValidation.validateSync({ subject: emailConfig.subject, previewText: emailConfig.previewText });
    sendTimeValidation.validateSync({ sendTime: new Date(sendTime) });
    messageValidation.validateSync({ message });
    if (!template.templateId || !template.version) {
      return 'Please select a campaign template.';
    }
    return null;
  } catch (error: any) {
    return error.message ?? '';
  }
};
